<template>
  <div v-if="campaign">
    <!-- Breadcrumb -->
    <v-row class="mt-n3">
      <v-col cols="12">
        <v-card class="ma-0 py-0">
          <v-breadcrumbs :items="breadcrumbItems">
            <template v-slot:divider>
              <v-icon color="primary">
                mdi-chevron-right
              </v-icon>
            </template>
          </v-breadcrumbs>
        </v-card>
      </v-col>
    </v-row>
    
    <v-sheet elevation="6">
      <v-tabs
        v-if="campaign"
        v-model="actionTab"
        show-arrows
      >
        <v-tabs-slider color="primary" />

        <v-tab
          v-model="actionTab"
          v-for="(tab, index) in actionsTabs"
          :key="index"
          @click="actionTabRouter(index)"
          class="d-flex justify-start"
          :style="{ minWidth: '100px' }"
          :disabled="!tab.isVisible"
        >
          <v-icon class="mr-1">{{ tab.icon }}</v-icon>
          <span class="mx-auto">{{ tab.label }}</span>
        </v-tab>
        
        <v-tabs-items v-model="actionTab" >
          <v-row class="my-2 mt-3">
            <v-col cols="12">
              <v-expansion-panels v-model="isPanelExpanded">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <!--
                    <div class="d-flex justify-between align-center mouse-pointer font-weight-bold"
                      :style="{ width: `${100}%`, height: `${50}px` }">
                      -->
                    <div class="d-flex justify-between align-center mouse-pointer font-weight-bold"
                      style="width:100%; height:auto;">
                      <v-tooltip right :color="campaign.status ? getVuetifyColor(campaign.status.color) : 'white'">
                          <template v-slot:activator="{ attrs, on }">
                              <span
                                  v-bind="attrs"
                                  v-on="on"
                                  :style="{ borderLeft: `7px solid ${campaign.status ? getVuetifyColor(campaign.status.color) : 'white'}`, height: '50px' }"
                              ></span>
                          </template>
                          <span :style="{ color: 'white' }">{{ `Campaign is ${campaign.status ? campaign.status.type : 'none'}` }}</span>
                      </v-tooltip>
                      <span @click.stop="openCampaignEditModal(campaign, 'showCampaignEditFormDialog')">
                          <v-img
                              v-if="campaign.logo"
                              :src="campaign.logo.urlThumb"
                              width="50"
                              height="50"
                              style="cursor: pointer"
                          />
                          <v-img
                              v-else
                              src="d-clamp-holder.jpg"
                              width="50"
                              height="50"
                              style="cursor: pointer"
                          />
                      </span>
                      <span class="ml-3" >{{ campaign.name }}</span>
                      <div class="font-weight-light d-flex align-center">
                        <v-tooltip top>
                          <template v-slot:activator="{ attrs, on }">
                            <span v-bind="attrs" v-on="on" class="ml-2 text-no-wrap">{{ campaign.startDate | Moment_DD_MMM_YYYY }} -
                            </span>
                          </template>
                          <span>Start Date</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ attrs, on }">
                            <span v-bind="attrs" v-on="on" class="ml-1 mr-2 text-no-wrap"> {{ campaign.endDate | Moment_DD_MMM_YYYY
                              }}</span>
                          </template>
                          <span>End Date</span>
                        </v-tooltip>
                      </div>
                      <div style="width:100%" v-if="actionTab < 3">
                        <div v-for="asset in this.campaignAssets.filter(x=>x.promoted)" :key="index" class="px-1" style="height:50px; display:inline-block;">
                          <div class="font-weight-light d-flex align-center text-center" style="background-color: #333333; height:80%;border-radius: 10px;">
                            <v-tooltip top>
                              <template v-slot:activator="{ attrs, on }">
                                <span v-bind="attrs" v-on="on" class="mx-4 text-no-wrap">
                                  Media Plan<br>
                                  <span class="primary--text text-no-wrap">Uploaded: {{ asset.dateCreated | Moment_DD_MMM_YYYY }}</span>
                                </span>
                              </template>
                              <span>{{ asset.media.originalFileName }}</span>
                            </v-tooltip>
                          </div>
                        </div>
                      </div>
                      <div class="ml-auto font-weight-light d-flex align-center">
                        <!-- K Number -->
                          <v-tooltip top>
                            <template v-slot:activator="{ attrs, on }">
                              <span
                                v-if="$store.state.Permissions.campaignKNumberView"
                                v-bind="attrs"
                                v-on="on"
                                class="mr-6"
                                @click.stop="openCampaignEditModal(campaign, 'showCampaignEditFormDialog')"
                              >{{ campaign.kNumber ? campaign.kNumber : 'Not set' }}</span>
                            </template>
                            <span>K Number</span>
                          </v-tooltip>
                          <!-- Process Priority -->
                          <v-tooltip top v-if="hasCampaignProcessPriorityPermission">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                x-small 
                                color="#363636"
                                class="mr-4 py-4 elevation-0"
                                fab
                                >
                                  <ProcessPriority
                                    :campaign="campaign"
                                    :hasCampaignProcessPriorityPermission="hasCampaignProcessPriorityPermission"
                                    />
                              </v-btn>
                            </template>
                            <span>Turn Report Processing {{campaign.processPriority == 1 ? "Off" : "On"}}</span>
                          </v-tooltip>  
                          <!-- Jump Off to Graph IF Verified tab-->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                v-show="actionTab === 0" 
                                @click.stop="addFormats = !addFormats"
                                x-small 
                                color="secondary"
                                class="mr-4 py-4 elevation-0"
                                fab
                                >
                                <v-icon x-small>mdi-arrow-expand</v-icon>
                              </v-btn>
                            </template>
                            <span>Show/Hide Formats</span>
                          </v-tooltip>  
                          <!-- Create burst -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                v-show="actionTab === 0  && $store.state.Permissions.burstAdd" 
                                @click.stop="burstCreateDialog = true" 
                                x-small 
                                color="primary"
                                class="mr-4 py-4 elevation-0"
                                fab
                                >
                                <v-icon x-small> mdi-plus</v-icon>
                              </v-btn>
                            </template>
                            <span>Create new burst</span>
                          </v-tooltip>
                          <!-- Add screen to burst -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                v-show="actionTab === 0 && $store.state.Permissions.screenAdd && campaign.campaignBursts && campaign.campaignBursts.length > 0" 
                                @click.stop="
                                  availableFormatsDialog = true;
                                  addScreenGlobally = true;
                                " 
                                x-small 
                                color="fourth"
                                class="mr-4 py-4 elevation-0"
                                fab
                                >
                                <v-icon x-small>mdi-monitor-screenshot</v-icon>
                              </v-btn>
                            </template>
                            <span>Add Screen(s) To Burst(s)</span>
                          </v-tooltip>
                          
                          <!-- Jump Off to Graph IF Verified tab-->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                v-show="actionTab === 2" 
                                @click.stop="scrollToGraph"
                                x-small 
                                color="primary"
                                class="mr-4 py-4 elevation-0"
                                fab
                                >
                                <v-icon x-small>mdi-chart-line</v-icon>
                              </v-btn>
                            </template>
                            <span>Jump to Graph</span>
                          </v-tooltip>
                          <!-- Download artwork specs - Campaign Level -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-on="on" v-bind="attrs" @click.native.stop="downloadArtworkSpecs(campaign,null)"
                                color="green" fab  x-small 
                                class="mr-4 py-4 elevation-0"
                                v-if="artworkSpecsDownload">
                                <v-icon>mdi-microsoft-excel</v-icon>
                              </v-btn>
                            </template>
                            <span>Download Campaign Artwork Specs</span>
                          </v-tooltip>
                          <!-- Generate CFS  
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                v-show="actionTab === 0 && $store.state.Permissions.screenAdd && campaign.campaignBursts && campaign.campaignBursts.length > 0" 
                                @click.stop="showCreateCFS = true"
                                x-small 
                                color="indigo darken-1"
                                class="mr-4 py-4 elevation-0"
                                fab
                                >
                                <v-icon x-small>mdi-cogs</v-icon>
                              </v-btn>
                            </template>
                            <span>Generate CFS</span>
                          </v-tooltip>-->
                          <!-- Download PCA Report
                          <v-tooltip top v-if="$store.state.Permissions.pcaReportDownload">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                v-show="actionTab === 2" 
                                @click.stop="downloadPCAReport(campaign.id)"
                                x-small 
                                color="blue"
                                class="mr-4 py-4 elevation-0"
                                fab
                                :disabled="generatingPCA"
                                :loading="generatingPCA"
                                >
                                <v-icon x-small>mdi-microsoft-excel</v-icon>
                              </v-btn>
                            </template>
                            <span>Download PCA</span>
                          </v-tooltip>-->
                          
                          <!-- Download Dashboard Report 
                          <v-tooltip top v-if="$store.state.Permissions.analyticsReportDownload">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                v-show="actionTab === 1 || actionTab === 2" 
                                @click.stop="downloadDashboardReport(campaign.id, routeRequest.id)"
                                x-small 
                                color="green"
                                class="mr-4 py-4 elevation-0"
                                fab
                                :disabled="generatingDashboardReport"
                                :loading="generatingDashboardReport"
                                >
                                <v-icon x-small>mdi-microsoft-excel</v-icon>
                              </v-btn>
                            </template>
                            <span>Download Dashboard Report</span>
                          </v-tooltip> -->

                          <!-- Download Dashboard Report -->
                          <v-tooltip top v-if="$store.state.Permissions.analyticsReportDownload">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                v-show="actionTab === 1 || actionTab === 2" 
                                @click.stop="downloadPostCampaignAnalysisPCAReport(campaign.id, routeRequest.id)"
                                x-small 
                                color="green"
                                class="mr-4 py-4 elevation-0"
                                fab
                                :disabled="generatingPostCampaignAnalysisPCAReport"
                                :loading="generatingPostCampaignAnalysisPCAReport"
                                >
                                <v-icon x-small>mdi-microsoft-excel</v-icon>
                              </v-btn>
                            </template>
                            <span>Download Post-Campaign Analysis (PCA)</span>
                          </v-tooltip>
                          <!-- Show all bursts -->
                          <v-hover v-slot="{ hover }">
                            <v-btn 
                              v-show="(actionTab <= 4 && selectedBurst != 0)" 
                              @click="selectedBurst = 0"
                              :color="hover ? 'primary' : '#363636'"
                              style="transition: all 0.2s ease-in-out; "
                              depressed
                              small
                              class="mr-4"
                            >
                              Show all bursts
                            </v-btn>
                          </v-hover>
                          
                          <!-- Progress bar-->
                          <v-tooltip top>
                            <template v-slot:activator="{ attrs, on }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                                class="mr-10"
                                >
                                <!--  -->
                                  <v-progress-circular
                                    :value="getProgress(campaign)"
                                    size="30"
                                    width="4"
                                    color="primary"
                                  />
                              </span>
                            </template>
                            <span>{{ getProgress(campaign) }} / 100 %</span>
                          </v-tooltip>
                        </div> 
                      </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="hasCampaignMilestoneViewPermission">
                  <div class="d-flex align-center justify-end my-4">
                    <div v-for="item in [{name: 'Completed', color: '#499F68'}, {name: 'In progress', color: '#F464A9'}, {name: 'Pending', color: '#4AA6D5'}, {name: 'No Milestone', color: 'black'}]">
                        <v-tooltip top>
                            <template v-slot:activator="{ attrs, on }">
                                <div
                                    v-bind="attrs"
                                    v-on="on"
                                    class="d-flex align-center text-caption mr-4"
                                >
                                    <div 
                                        :style="{ backgroundColor: item.color, width: '15px', height: '15px', borderRadius: '50%' }"
                                        class="mx-2"
                                    ></div>
                                    {{ item.name }}
                                </div>
                            </template>
                            <span>{{ item.name }}</span>
                        </v-tooltip>
                    </div>
                    </div>
                  <div class="d-flex align-center" style="min-width: 100%; height: 25px; border-radius: 10px" v-if="campaign.campaignBursts.length >= 1"> 
                    <v-tooltip top v-for="burst in campaign.campaignBursts" :key="burst.id">
                      <template v-slot:activator="{ on, attrs }" >
                        <v-hover v-slot="{ hover }" >
                          <!-- Sub Issue of an epic display  -->
                          <div 
                            v-bind="attrs"
                            v-on="on"
                            class="text-center"
                            :class="campaign.campaignBursts.length > 1 ? 'subIssue' : 'oneIssue'" 
                            @click.prevent="handleProgressClick(burst.id, campaign)"
                            :style="{
                              backgroundColor: burst.workflowStatus ? filterColor(burst.workflowStatus.s) : 'black',
                              width: `${100 / campaign.campaignBursts.length}%`,
                              height: '25px', 
                              cursor: 'pointer',
                              // If the selectedBurst is not null, then we want to highlight the selected burst using opacity 
                              opacity: selectedBurst
                                ? selectedBurst === burst.id
                                  ? '1'
                                  : hover
                                  ? '0.7'
                                  : '0.5'
                                : hover
                                ? '0.7'
                                : '1',
                            }">
                            <span class="customF" style="font-size: 12px;">{{ formatBurstProgressName(campaign, burst) }}</span>
                          </div>
                        </v-hover>
                      </template>
                      <span>{{ burst.name }}</span>
                    </v-tooltip>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            </v-col>
          </v-row>
          <!-- Bursts Manage + Verify Route Collected Dialog -->
          <v-tab-item 
            v-show="actionsTabs.find(tab => tab.label.toLowerCase() === 'bursts').isVisible"
            class="pa-4"
            :transition="false"
          >
            <!-- Bursts Manage -->
            <v-row class="d-flex justify-center mt-3">
              <BurstsManage 
                :campaign="campaign"
                @getCampaignByIdFromChild="updateBurstCalled(); getCampaignById();"
                :selectedBurst="selectedBurst"
                :deliveryTypes="deliveryTypes"
                :style="{ maxWidth: '100%' }"
                :formats="formats"
                :addFormats="addFormats"
                :showFormats="showFormats"
              />
            </v-row>
          </v-tab-item>
          <!-- Reported -->
          <v-tab-item 
            v-show="actionsTabs.find(tab => tab.label.toLowerCase() === 'tracking').isVisible && $store.state.Permissions.campaignHistoricalStats || $store.state.Permissions.analytics"
            class="pa-4"
            :transition="false"
          >
            <AudienceStringSelector
              :campaign-id="parseInt(campaignId)"
              :isLoading="isLoading"
              @route-requests-updated="handleRouteRequestsUpdated"
              @route-request-added="handleRouteRequestAdded"
              @route-request-changed="handleRouteRequestChanged"
              :incomingRouteRequest="routeRequest"
              v-show="false"
            />

            <Report
              v-show="selectedBurst == 0 || selectedBurst == burst.id && tabParamName === 'tracking'"
              v-for="(burst, index) in lazyLoadBursts['reports']"
              :burst="burst"
              :campaign="campaign"
              :data="getBurstStats(burst.id)"
              :kpiList="kpiList"
              :kpiListObjects="kpiListObjects"
              v-bind:index="index"
              v-bind:key="tab.label+`_`+burst.id"
              :type="'tracking'"
              :arbSync="arbSync[index]"
            />
          </v-tab-item>

          <!-- Verified -->
          <v-tab-item 
            v-show="actionsTabs.find(tab => tab.label.toLowerCase() === 'verified').isVisible && $store.state.Permissions.campaignHistoricalStats || $store.state.Permissions.analytics"
            class="pa-4"
            :transition="false"
          >
          <AudienceStringSelector
            :campaign-id="parseInt(campaignId)"
            :isLoading="isLoading"
            @route-requests-updated="handleRouteRequestsUpdated"
            @route-request-added="handleRouteRequestAdded"
            @route-request-changed="handleRouteRequestChanged"
            :incomingRouteRequest="routeRequest"
          />

                
              <!-- Playout Topper -->
              <PlayoutTop :data="totals" />
              <!-- Report -->
                <Report
                  v-show="selectedBurst == 0 || selectedBurst == burst.id && tabParamName === 'verified'"
                  v-for="(burst, index) in lazyLoadBursts['reports']"
                  :burst="burst"
                  :campaign="campaign"
                  :data="getBurstStats(burst.id)"
                  :kpiList="kpiList"
                  :kpiListObjects="kpiListObjects"
                  v-bind:index="index"
                  v-bind:key="tab.label+`_`+burst.id"
                  :type="'verified'"
                  :arbSync="arbSync[index]"
                />
        
                <!-- Graph -->
                <RouteGraph
                  ref="graph"

                  :campaign="campaign"
                  :kpiList="kpiList"
                  :kpiListObjects="kpiListObjects"
                  :data="dashboardStructureByCampaign"

                  :chartMetric="chartMetric"
                  @updateChartMetric="chartMetric = $event"

                  :chartDataType="chartDataType"
                  @updateChartDataType="chartDataType = $event"

                  :chartMediaOwners="chartMediaOwners"
                  @updateChartMediaOwners="chartMediaOwners = $event"

                  :chartScreens="chartScreens"
                  @updateChartScreens="chartScreens = $event"

                  :selectedBurst="selectedBurst ? selectedBurst : 0"
                  @updateSelectedFilterBurst="selectedBurst = $event"
                  :arbSync="sumValues(arbSync)"
                  :type="'verified'"
                  :routeRequest="routeRequest"
                  v-if="tabParamName === 'verified' && lazyBurstsLoaded"
                />
                <!-- Targets Table -->
                <RouteTargets
                  v-show="(selectedBurst == 0 || selectedBurst === burst.id) && hasAnalyticsEditPermission === true && tabParamName === 'verified'"
                  v-for="(burst, index) in lazyLoadBursts['targets']"
                  :key="index"
                  :burst="burst"
                  :campaign="campaign"
                  :chartMetric="chartMetric"
                  :chartDataType="chartDataType"
                  :chartMediaOwners="chartMediaOwners"
                  :chartScreens="chartScreens"
                  :data="getBurstStats(burst.id)"
                  :kpiList="kpiList"
                  :kpiListObjects="kpiListObjects"
                  :arbSync="arbSync[index]"
                  :type="'verified'"
                  :getRouteRequestLabel="getRouteRequestLabel"
                  :routeRequest="routeRequest"
                />
                
          </v-tab-item>

          <!-- Playout -->
          <v-tab-item 
            v-show="actionsTabs.find(tab => tab.label.toLowerCase() === 'playout').isVisible && $store.state.Permissions.playoutTabCampaignView"
            class="pa-4"
            :transition="false"
          >
          <AudienceStringSelector
            :campaign-id="parseInt(campaignId)"
            :isLoading="isLoading"
            @route-requests-updated="handleRouteRequestsUpdated"
            @route-request-added="handleRouteRequestAdded"
            @route-request-changed="handleRouteRequestChanged"
            :incomingRouteRequest="routeRequest"
          />
            <Report
              v-show="selectedBurst == 0 || selectedBurst == burst.id && tabParamName === 'playout'"
              v-for="(burst, index) in lazyLoadBursts['reports']"
              :burst="burst"
              :campaign="campaign"
              :data="getBurstStats(burst.id)"
              :kpiList="kpiList"
              :kpiListObjects="kpiListObjects"
              v-bind:index="index"
              v-bind:key="tab.label+`_`+burst.id"
              :type="'playout'"
              :arbSync="arbSync[index]"
            />
            <ReportPlayoutVue
            :campaigns="campaigns"
            :kNumber="campaign.kNumber"
            :incomingRouteRequest="routeRequest"
            :hideTargets="true"
            v-if="this.$store.state.Permissions.playoutTabCampaignView"/>
          </v-tab-item>
          

          <!-- Live -->
          <v-tab-item
            v-show="actionsTabs.find(tab => tab.label.toLowerCase() === 'live').isVisible && $store.state.Permissions.campaignLiveStats"
            class="px-4"
            :transition="false"
          >
            <v-row class="my-6 mx-2 pa-0 align-center">
              <v-icon large class="mr-2 mb-1">mdi-poll</v-icon>
              <h1 style="font-weight: 400;">Live stats</h1>
            </v-row>
            <v-row class="mt-8">
              <template v-for="dashboardAnalytic in mergedDashboardAnalytics">
                <v-card
                  v-if="dashboardAnalytic.title === 'LIVE'"
                  :key="dashboardAnalytic.title"
                  class="d-flex flex-column justify-end ma-0 py-0 px-2"
                  style="width: 100%; height: 100%; border-radius: 0;"
                >
                  <v-row align="end">
                    <v-col cols="4" class="ma-0 mb-2 pa-0 px-4">
                      <theme-material-stats-card
                        style="background: transparent"
                        color="tertiary"
                        icon="pat-pending"
                        title="Plays All time"
                        :value="String(mergedDashboardAnalytics[0].playsAllTime)"
                      />

                      <theme-material-stats-card
                        style="background: transparent"
                        color="primary"
                        icon="pat-pending"
                        title="Plays Today"
                        :value="String(mergedDashboardAnalytics[0].playsToday)"
                      />

                      <theme-material-stats-card
                        style="background: transparent"
                        color="secondary"
                        icon="pat-pending"
                        title="Plays this week"
                        :value="String(mergedDashboardAnalytics[0].playsWeek)"
                      />
                    </v-col>

                    <!-- Live Chart -->
                    <v-col
                      v-if="barChartOptions.series.length > 0"
                      cols="8"
                      class="ma-0 pa-0 px-4 text-right"
                    >
                      <v-row>
                        <v-spacer />
                        <v-col cols="1" class="d-flex justify-center">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                fab
                                small
                                color="primary"
                                @click="refreshChartData()"
                                class="mt-1"
                              ><v-icon small>mdi-refresh</v-icon></v-btn>
                            </template>
                            <span>Last Updated: {{chartUpdatedTime | fullMoment}}</span>
                          </v-tooltip>
                        </v-col>

                        <v-col cols="2" class="d-flex justify-center">
                          <v-select
                            v-model="barChartTimeOption"
                            label="Interval"
                            outlined
                            :items="['day', 'hour']"
                          />
                        </v-col>
                      </v-row>

                      <apexchart
                        v-if="renderBarChart"
                        height="475"
                        :options="barChartOptions"
                        :series="barChartOptions.series"
                        style="color:black"
                      />
                    </v-col>

                    <!-- Skeleton loader -->
                    <v-col
                      v-else
                      cols="8"
                      class="ma-0 pa-0 px-4 text-right"
                    >
                      <v-img src="/assets/skeleton/chart-535x395.gif" />
                    </v-col>
                  </v-row>
                </v-card>
              </template>
            </v-row>
            <v-row 
              v-if="$store.state.Permissions.campaignEdit"
              class="my-6 mx-2 pa-0 align-center"
            >
              <v-icon large class="ma-2">mdi-table-large</v-icon>
              <h1 style="font-weight: 400;">Ping Back Table</h1>
              <div
                class="pa-2 ma-2"
                style="justify-content: center"
              >
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  fab
                  small
                  color="primary"
                  @click="getLatestPingBacks()"
                  class="mt-1"
                ><v-icon small>mdi-refresh</v-icon></v-btn>
              </div>
            </v-row>
            <v-row
              v-if="$store.state.Permissions.campaignEdit && latestPingBacks.length > 0"
              class="mb-6 mx-6 pa-0 align-center"
            >
              <div
                style="height:600px;overflow-y: auto;"
              >
                <table
                  style="border-collapse: collapse;width: 100%;"
                >
                  <thead
                    style="position: sticky;top: 0;z-index: 1;"
                  >
                    <tr>
                      <th 
                        v-for="(header, index) in Object.keys(latestPingBacks[0])" :key="index"
                        style="background-color: #555555;border: 1px solid darkgray;padding: 8px;text-align: left;"
                      >{{ header }}</th>
                    </tr>
                  </thead>
                  <tbody
                    style=""
                  >
                    <tr style="border-color: darkgray;" v-for="(item, index) in latestPingBacks" :key="index">
                      <td style="padding:8px;text-align:left;border:1px solid darkgray;" v-for="(value, colIndex) in Object.values(item)" :key="colIndex">{{ value }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-row>
          </v-tab-item>


          <!-- Heartbeat Report 
          <v-tab-item 
            v-show="actionsTabs.find(tab => tab.label.toLowerCase() === 'reports').isVisible && $store.state.Permissions.reportingGenerate"
            class="pa-4"
            :transition="false"
          >
            <v-row class="my-6 mx-2 pa-0 align-center">
              <v-icon large class="mr-2 mb-1">mdi-file-chart</v-icon>
              <h1 style="font-weight: 400;">Reports</h1>
            </v-row>
            <CampaignGenerateReport    
              :campaignId="campaign.id"
              :style="{
                height: '650px',
              }"
            />
          </v-tab-item>-->


          
          <!-- Stakeholders Involved in campaign -->
          <v-tab-item 
            v-show="actionsTabs.find(tab => tab.label.toLowerCase() === 'stakeholders').isVisible && $store.state.Permissions.usersAndStakeholders"
            class="pa-4"
            :transition="false"
          >
            <!-- 
            <v-row class="my-6 mx-2 pa-0 align-center">
              <v-icon large class="mr-2 mb-1">mdi-billboard</v-icon>
              <h1 style="font-weight: 400;">Stakeholders</h1>
            </v-row> -->
            <CampaignStakeholders
              class="mt-n10"
              :campaignId="campaign.id"
            />
          </v-tab-item>

          <!-- Library for assets such as fonts -->
          <v-tab-item
            v-show="actionsTabs.find(tab => tab.label.toLowerCase() === 'library').isVisible && $store.state.Permissions.libraryEdit"
            class="px-4" :transition="false">
            <campaign-assets-form v-if="campaign" :campaign="campaign" />
          </v-tab-item>

          <!-- Details 
          <v-tab-item
            v-show="actionsTabs.find(tab => tab.label.toLowerCase() === 'details').isVisible && $store.state.Permissions.campaignEdit"
            class="px-4"
          >
            <v-row class="my-6 mx-2 pa-0 align-center">
              <v-icon large class="mr-2 mb-1">mdi-book-edit</v-icon>
              <h1 style="font-weight: 400;">Edit Campaign</h1>
            </v-row>
            <campaign-edit-form
              :campaign="campaign"
              @getCampaignByIdFromChild="updateBurstCalled(); getCampaignById()"
            />
          </v-tab-item>-->
          <!-- Pop Gallery -->
          <v-tab-item
            v-show="actionsTabs.find(tab => tab.label.toLowerCase() === 'pop gallery').isVisible && $store.state.Permissions.proofOfPlay"
            class="px-4"
            :transition="false"
          >
          <!--
            <v-row class="my-6 mx-2 pa-0 align-center">
              <v-icon large class="mr-2 mb-1">mdi-camera-marker-outline</v-icon>
              <h1 style="font-weight: 400;">Pop gallery</h1>
            </v-row>-->
            <PopGallery
              v-if="campaign" 
              :campaign="campaign"
              class="mb-4"
              />
          </v-tab-item>
          <!-- Campaign Global Map -->
          <v-tab-item
            v-show="actionsTabs.find(tab => tab.label.toLowerCase() === 'map').isVisible && $store.state.Permissions.proofOfPlay"
            class="px-4"
            :transition="false"
          >
            <CampaignMapView v-bind:cid="parseInt($route.query.cid)"/>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-sheet>
    <!--- Create burst DIALOG -->
    <v-dialog v-model="burstCreateDialog" width="600" :retain-focus="false" content-class="noscrollbar">
      <create-burst :campaign="campaign" :deliveryTypes="deliveryTypes" :overlay="overlay" @closeDialog="burstCreateDialog = false" />
    </v-dialog>
    <!-- Add screen to burst DIALOG-->
    <v-dialog :retain-focus="false" 
      v-model="availableFormatsDialog" 
      width="900" 
      style="min-height: 400px;"
      @input="
        addScreenGlobally = false;
        selectedBurstToAddScreen = null;
      "
      content-class="noscrollbar"
    >
    <v-card>
      <v-stepper v-model="e1">
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 1"
            step="1"
          >
          Choose an available screen
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="e1 > 2"
            step="2"
          >
          Assign to a burst
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-row class="pa-4 mb-4" style="min-height: 400px">
              <v-col cols="12" class="mt-4 py-0 mb-n2">
                <v-text-field 
                  v-model="searchFieldScreens" 
                  outlined 
                  class="my-0 py-0" 
                  label="Search Screens"
                  @keyup.enter.native="updatedSearchFormats"
                  @click:append="updatedSearchFormats"
                  @keyup.native="updatedSearchFormats"
                  append-icon="mdi-magnify"
                  clearable
                  @click:clear="clearSearch"
                  ></v-text-field>
              </v-col>
              <v-col cols="12" class="my-0 py-0">
                <v-row class="my-0 py-0">
                  <v-data-table 
                    :headers="headers" 
                    :items="quickSortFormats(formats)" 
                    :expanded.sync="expanded" 
                    item-key="id"
                    :loading="dataTableLoading"
                    :style="$vuetify.theme.dark ? 'background-color: #272727' : ''"
                    show-expand style="width: 100%">
                    <template v-slot:expanded-item="{ item }">
                      <td>
                      </td>
                      <td>
                        <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.deliveryType.name }}</p>
                      </td>
                      <td>
                        <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.category }}</p>
                      </td>
                      <td>
                        <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.width }}</p>
                      </td>
                      <td>
                        <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.height }}</p>
                      </td>
                      <td>
                        <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.aspectRatio }}</p>
                      </td>
                      <td>
                        <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.orientation }}</p>
                      </td>
                      <!--
                    <td>
                      <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.brandingBadgeScreen == null ? 0 : screen.screen.brandingBadgeScreen.length }}</p>
                    </td>
                    <td>
                      <p v-for="screen in item.screens.filter(x => x.id > 0)">{{ screen.screen.numberOfScreens }}</p>
                    </td>
                    -->
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn class="m-2" icon small color="primary" @click="
                        addFormatDialog = true;
                        selectedScreen = item;
                        selectedScreen.date = null;
                        e1 = 2
                      ">
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="2">
              <v-row class="pa-4">
                <v-col cols="6">
                  <v-select 
                    v-if="addScreenGlobally"
                    v-model="selectedBurstToAddScreen" 
                    outlined 
                    label="Add Screen To"
                    multiple 
                    :items="campaign.campaignBursts" 
                    item-text="name" 
                    :item-value="'name'" 
                    attach color="primary"
                    return-object 
                  />
                </v-col>
                <v-col v-if="addScreenGlobally === false" cols="6">
                  <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox 
                        v-model="selectedDate.date" 
                        outlined range 
                        label="Screen Start/End Date"
                        prepend-icon="mdi-calendar" 
                        readonly 
                        clearable 
                        v-bind="attrs" v-on="on" 
                        />
                    </template>
                    <v-date-picker v-model="selectedDate.date" clearable range no-title scrollable />
                  </v-menu>
                </v-col>
                <v-col :cols="addScreenGlobally === false ? '12' : '6'">
                  <v-select 
                    v-model="selectedTriggers" 
                    outlined multiple label="Triggers" 
                    item-text="friendlyName"
                    item-value="paramName" 
                    :items="availableTriggers" />
                </v-col>
              </v-row>
              <v-row class="mb-4 px-7 mt-n2 d-flex justify-space-between ">
            <v-btn @click="e1 = 1">
              Back
            </v-btn>
            <v-btn :disabled="disableAddScreenBtn" color="primary" 
              @click="addScreenToBurst() "
            >
              Save
            </v-btn>
          </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
    </v-dialog>
    <!-- Generate CFS Modal -->
    <CreateCFS v-if="showCreateCFS"
      :showCreateCFS="showCreateCFS"
      :campaign="campaign"
      :bursts="campaign.campaignBursts"
      :mediaOwners="mediaOwnersFromBurst"
      @closeDialog="showCreateCFS = false"
    />
    <!-- Campaign Edit Dialog -->
    <v-dialog
      v-model="showCampaignEditFormDialog"
      @click:outside="showCampaignEditFormDialog = false;"
      width="500"
    >
      <v-card>
        <v-card-title class="white--text primary">
          Edit Campaign
        </v-card-title>

        <campaign-edit-form
          v-if="showCampaignEditFormDialog && campaign"
          :campaign="campaign"
          @getCampaignByIdFromChild="updateBurstCalled(); getCampaignById()"
          @closeEditDialog="showCampaignEditFormDialog = false"
        />
      </v-card>
    </v-dialog>
    <!-- Issue modal component -->
    <IssueModal
        v-if="openIssueModal"
        :selectedIssue="selectedIssue"
        :selectedBurst="softSelectedBurst"
        :selectedCampaign="campaign"
        :issueIndex="issueIndex"
        :openIssueModal="openIssueModal"
    />
  </div>
</template>

<script>
  // Views
  // import CampaignGenerateReport from '@/components/campaign/GenerateReport' // Not used

  // Components
  // import CampaignMaterialCard from '@/components/campaign/MaterialCard' // Not used
  const BurstsManage = () => import('@/components/bursts/ManageBurst');
  const CampaignEditForm = () => import('@/components/campaign/EditForm');
  const CampaignAssetsForm = () => import('@/components/campaign/AssetsForm');  
  const CampaignStakeholders = () => import('@/components/campaign/Stakeholders');
  const RouteGraph = () => import('@/components/verify/route/Graph');
  const RouteTargets = () => import('@/components/verify/route/Targets');
  const PopGallery = () => import('@/components/pop/Index');
  const CampaignMapView = () => import('@/components/map/CampaignMapView');
  const Report = () => import('@/components/verify/route/Report');
  const CreateBurst = () => import('@/components/campaigns/CreateBurst');
  const CreateCFS = () => import('@/components/cfs/CreateCFS')
  const ProcessPriority = () => import('@/components/campaigns/ProcessPriority')
  const ReportPlayoutVue = () => import('@/views/dashboard/reporting/ReportPlayoutVue')
  const PlayoutTop = () => import('@/views/dashboard/reporting/playoutComponents/PlayoutTop.vue')
  const IssueModal = () => import('@/components/campaigns/modals/ProgressIssueModal')
  const AudienceStringSelector = () => import('@/views/dashboard/campaigns/campaign/AudienceStringSelector.vue')

  // Services
  import RouteController from '@/services/controllers/Route'
  import ReportingController from '@/services/controllers/Reporting'
  import CampaignController from '@/services/controllers/Campaign'
  import ScreenController from '@/services/controllers/Screen'

  // Mixins
  //import helpersColor from '@/mixins/helpers/color'
  //import roundNumber from '@/mixins/helpers/roundNumber'
  import localStorage from '@/mixins/local/storage'
  import vuetifyColors from '@/mixins/vuetify/colors'
  import campaignManagement from '@/mixins/helpers/campaignManagement'

  // External libraries
  import moment from 'moment'
  import apexchart from 'vue-apexcharts'
  import uniqBy from 'lodash/uniqBy';

  const triggersList = require(`@/assets/${process.env.VUE_APP_TRIGGERS_VERSION}`)
  
  export default {
    mixins: [
      //helpersColor,
      //roundNumber,
      localStorage,
      vuetifyColors,
      campaignManagement
    ],

    components: {
      //CampaignMaterialCard,
      apexchart,
      BurstsManage,
      CampaignEditForm,
      CampaignAssetsForm,
      CampaignStakeholders,
      //CampaignGenerateReport,
      RouteGraph,
      RouteTargets,
      PopGallery,
      CampaignMapView,
      Report,
      CreateBurst,
      CreateCFS,
      ProcessPriority,
      ReportPlayoutVue,
      PlayoutTop,
      AudienceStringSelector,
      IssueModal
    },

    filters: {
      moment: function (date) {
        return moment(date).format('DD MMM YYYY')
      },

      fullMoment: function (date) {
        return moment(date).format('DD MMM YYYY HH:mm:ss')
      },
      Moment_DD_MMM_YYYY: function (date) {
        return moment(date).format('DD MMM YYYY')
      },
    },
    provide: function () {
      return {
        updateBursts: this.updateBursts,
        closeIssue: this.closeIssue,
        filterColor: this.filterColor,
        openIssue: this.openIssue,
      }
    },
    
    data () {
      return {
        campaignAssets: [],
        routeVersions: [],
        demographics: [],
        granularityAudiences: [],
        campaigns: [],
        actionTab: 0,
        analyticsMode: 0,
        dashbooardTargets: null,
        campaign: null,
        campaignId: null,
        routeRequest: {},
        chartUpdatedTime: '',
        renderBarChart: false,
        barChartTimeOption: 'hour',
        generatingPCA: false,
        generatingDashboardReport: false,
        generatingPostCampaignAnalysisPCAReport: false,
        tabParamName: null,
        barChartOptions: {
          series: [],
          chart: {
            foreColor: this.$vuetify.theme.dark ? '#fff': '#000',
            events: {
              click: (event, chartContext, config) => {
                // The last parameter config contains additional information like `seriesIndex`
                // If its not a negative number it means its a valid bar chart value.
                if (config.seriesIndex >= 0) {
                  this.$router.push({
                    name: 'Creatives',
                    query: { id: this.$route.query.cid, tab: 1, bid: 1 },
                  })
                }
              },
            },
            type: 'bar',
            fontFamily: 'Roboto, sans-serif',
            stacked: false,
            stackType: '100%',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },

          },
          xaxis: {
            type: 'string',
            categories: [],
            labels: {
              show: true,
              style: {
                fontFamily: 'Roboto, sans-serif',
              },
            },
          },
          yaxis: {
            labels: {
              formatter: function (val) {
                return val.toFixed(0)
              },
              show: true,
              style: {
                colors: '#ffffff',
                fontFamily: 'Roboto, sans-serif',
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
        },

        barChartData: {},

        dashboardAnalytics: null,
        
        tab: 0,
        event: {},
        today: null,
        
        breadcrumbItems: [
          {
            text: 'Dashboard',
            disabled: false,
            href: '/#/dashboard/campaigns',
          },
          {
            text: 'Dashboard',
            disabled: true,
          },
        ],

        chartMetric: '',
        chartDataType: 'auto',
        chartMediaOwners: [],
        chartScreens: [],
        campaignStatus: null,
        reportedCampaignBursts: [],
        selectedBurst: 0,
        softSelectedBurst: null,
        selectedCampaign: null,
        // Variables from manage burst to impletent the btns on progress bar
        burstCreateDialog: false,
        addFormatDialog: false,
        deliveryTypes: [],
        availableFormatsDialog: false,
        addScreenGlobally: false,
        formats: [], // list of all formats
        searchFieldScreens: null,
        selectedScreen: {},
        selectedDate: { date: null },
        selectedTriggers: [],
        selectedBurstToAddScreen: null,
        dashboardStructureByCampaign: {},
        dashboardStructureByCampaignData: {},
        dashboardStatMetricTypes: [],
        availableTriggers: [],
        disableAddScreenBtn: false,
        kpiList: [],
        kpiListObjects: {},
        headersAddedScreen: [
        {
          text: 'Media Owner',
          align: 'start',
          sortable: true,
          value: 'screen.mediaOwner.friendlyName',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'screen.name',
        },
        {
          text: 'Category',
          align: 'start',
          sortable: true,
          value: 'screen.category',
        },
        {
          text: 'Width',
          align: 'start',
          sortable: true,
          value: 'screen.width',
        },
        {
          text: 'Height',
          align: 'start',
          sortable: true,
          value: 'screen.height',
        },
        {
          text: 'Aspect ratio',
          align: 'start',
          sortable: true,
          value: 'screen.aspectRatio',
        },
        {
          text: 'Frame/s',
          align: 'start',
          sortable: false,
          value: 'locationCount',
        },
        {
          text: 'Start Date',
          align: 'start',
          sortable: true,
          value: 'startDate',
        },
        {
          text: 'End Date',
          align: 'start',
          sortable: true,
          value: 'endDate',
        },
        ],
        headers: [
          {
            text: 'Media Owner',
            align: 'start',
            sortable: true,
            value: 'mediaOwner.friendlyName',
          },
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Category',
            align: 'start',
            sortable: true,
            value: 'category',
          },
          {
            text: 'Width',
            align: 'start',
            sortable: true,
            value: 'width',
          },
          {
            text: 'Height',
            align: 'start',
            sortable: true,
            value: 'height',
          },
          {
            text: 'Aspect ratio',
            align: 'start',
            sortable: true,
            value: 'aspectRatio',
          },
          {
            text: 'Orientation',
            align: 'start',
            sortable: true,
            value: 'orientation',
          },
          {
            text: 'Actions',
            align: 'start',
            sortable: false,
            value: 'actions',
          },
          { text: '', value: 'data-table-expand' },
        ],
        expanded: [],
        openIssueModal: false,
        showCreateCFS: false,
        mediaOwnersFromBurst: [],
        showCampaignEditFormDialog: false,
        e1: 1,
        dataTableLoading: false,
        overlay: false,
        isPanelExpanded: 0, // Set the panel as expanded by default
        // Show Hide Bursts formats
        addFormats: false,
        showFormats: false,
        totals: [],
        arbSync: [],
        lazyLoadBursts: {},
        lazyBurstsLoaded: false,
        routeRequestForm: {
          routeVersionId: null,
          routeDemographicId: null,
          routeGranularityAudienceId: null,
          demographicCustom: '',
        },
        routeRequests: [],
        isLoading: true,
        deferLoading: true,
        latestPingBacks: [],
      }
    },

    computed: {
      getRouteRequestLabel() {
        return(routeRequestItem) => {
          let routeRequest = this.routeRequests.find(rr => rr.id === routeRequestItem.id)
          if(routeRequest === undefined) {
            return
          }
          // find the routeVersion
          let routeVersion = this.routeVersions.find(rv => rv.id === routeRequest.routeVersionId)
          if(routeVersion === undefined) {
            return
          }
          let routeVersionLabel = 'v' + routeVersion.routeReleaseId + '.' + routeVersion.routeReleaseRevision + ' [' + routeVersion.routeAlgorithmVersion + ']'
          // find the demographic
          let demographic = this.demographics.data.find(d => d.id === routeRequest.routeDemographicId)
          let demographicLabel = demographic ? demographic.description : ''
          // find the granularity
          let granularity = this.granularityAudiences.data.find(g => g.id === routeRequest.routeGranularityAudienceId)
          let granularityLabel = granularity ? granularity.description : ''
          let demographicCustomLabel = routeRequest.demographicCustom ? " : " + routeRequest.demographicCustom : ''
          return routeVersionLabel + " - " + granularityLabel + ", " + demographicLabel + demographicCustomLabel
        }
      },

      // function to get the bursts data
      getBurstStats() {
        return (burstId) => {
          if(this.dashboardStructureByCampaign.bursts)
            return this.dashboardStructureByCampaign.bursts.find(b => b.campaignBurstId === burstId)
          return {}
        }
      },

      hasCampaignMilestoneViewPermission() {
        return this.$store.state.Permissions.campaignMilestoneView
      },

      artworkSpecsDownload() {
        return this.$store.state.Permissions.artworkSpecsDownload
      },
      hasCampaignProcessPriorityPermission() {
          return this.$store.state.Permissions.reportingPriority
      },
      formatBurstProgressName(){
        return(campaign, burst) =>{
          let name = `${this.truncate(burst.name, 8)}`
          //  <span class="customF" style="font-size: 12px;">{{ truncate(burst.name, 8) }} - {{ campaign.campaignBursts.length < 8 && burst.startDate | moment }} - {{ burst.endDate | moment }}</span>
          if(campaign.campaignBursts.length < 6) {
            name = `${this.truncate(burst.name, 8)} - ${moment(burst.startDate).format('DD MMM YYYY')} - ${moment(burst.endDate).format('DD MMM YYYY')}`
          }
          return name
        }
      },
      filteredCampaignBursts(){
        let cbs = this.campaign.campaignBursts
        // Return only the selected burst (dont use .filter)
        if(this.selectedBurst != 0) {
          cbs = cbs.filter(burst => burst.id === this.selectedBurst)
          this.$nextTick()
        }
        return cbs
      },
       // Function to check is user has analyticsEdit permission
      hasAnalyticsEditPermission() {
        return this.$store.state.Permissions.analyticsEdit
      },

      mergedDashboardAnalytics() {
        // Filters based off user perms
        if(this.dashboardAnalytics && this.dashboardAnalytics.length > 0) {
          return this.dashboardAnalytics.filter(item => {
            if(item.title && item.title.toLowerCase() === 'live' && this.$store.state.Permissions.campaignLiveStats) {
              return item
            }
            else if(item.title && item.title.toLowerCase() === 'campaign' && this.$store.state.Permissions.campaignHistoricalStats) {
              return item
            }
            else if(item.title && item.title.toLowerCase() !== 'live' && item.title.toLowerCase() !== 'campaign' && this.$store.state.Permissions.burstHistoricalStats) {
              return item
            }
          });
        }

        return false
      },

      actionsTabs() {
        const tabs = []

          tabs.push({ label: 'Bursts', icon: 'mdi-source-branch', isVisible: true  })

          tabs.push({ label: 'Tracking', icon: 'mdi-chart-timeline-variant', isVisible: this.$store.state.Permissions.campaignHistoricalStats || this.$store.state.Permissions.analytics })
        
          tabs.push({ label: 'Verified', icon: 'mdi-check', isVisible: this.$store.state.Permissions.campaignHistoricalStats || this.$store.state.Permissions.analytics })

          tabs.push({ label: 'Playout', icon: 'mdi-play', isVisible: this.$store.state.Permissions.playoutTabCampaignView })
       
          tabs.push({ label: 'Live', icon: 'mdi-poll', isVisible: this.$store.state.Permissions.campaignLiveStats && this.mergedDashboardAnalytics })
      
         //  tabs.push({ label: 'Reports', icon: 'mdi-file-chart-check-outline', isVisible: false && this.$store.state.Permissions.reportingGenerate })

          tabs.push({ label: 'Stakeholders', icon: 'mdi-account-multiple', isVisible: this.$store.state.Permissions.usersAndStakeholders })

          tabs.push({ label: 'Library', icon: 'mdi-library', isVisible: this.$store.state.Permissions.libraryEdit })
        
          // tabs.push( {  label: 'Details',  icon: 'mdi-pencil',  //isVisible: this.$store.state.Permissions.campaignEdit , isVisible: false  })

          tabs.push( { label: 'PoP Gallery', icon: 'mdi-camera', isVisible: this.$store.state.Permissions.proofOfPlay })

          tabs.push( { label: 'Map', icon: 'mdi-map', isVisible: this.$store.state.Permissions.proofOfPlay })
        return tabs
      },
    },

    watch: {
      // watch selected burst, if it's > 0 then set isPanelExpanded to 1
      selectedBurst (val) {
        if (val > 0) {
          this.isPanelExpanded = 0
        }
      },

      async routeRequest(val,oldVal) {
        if(val && oldVal && val.id > 0 && val.id != oldVal.id)
        {
          this.isLoading = true
          this.deferLoading = true
          await this.fetchDashboardStatData()
        }
      },
      actionTab (val) {
        this.tab = val
        // Reset progress bar
        //this.selectedBurst = null
        this.softSelectedBurst = null
        this.selectedCampaign = null
      },
      '$vuetify.theme.dark' (val) {
        this.barChartOptions.xaxis.labels.style.colors = this.invertColor('#333', this.$vuetify.theme.dark)
      },
      barChartTimeOption (val) {
        this.renderBarChart = false
        if (val === 'day') {
          ReportingController.getChartValues({
            campaignId: parseInt(this.$route.query.cid),
            type: 'bar',
            indicator: 'live-plays-hour',
            granularity: 'day',
            playerId: 0,
          })
          .then((res) => {
            this.renderBarChart = true
            this.barChartOptions.xaxis.categories = []
            this.barChartOptions.xaxis.categories = res.data.categories
            this.barChartOptions.series = res.data.series
          })
        }
        else {
          ReportingController.getChartValues({
            campaignId: parseInt(this.$route.query.cid),
            type: 'bar',
            indicator: 'live-plays-hour',
            granularity: 'day',
            playerId: 0,
          })
          .then((res) => {
            this.renderBarChart = true
            this.barChartOptions.xaxis.categories = []
            this.barChartOptions.xaxis.categories = res.data.categories
            this.barChartOptions.series = res.data.series
          })
        }
      },
    },
    async mounted () {
      if(!this.campaignId) // fixes bug where this sometimes comes in as undefined
      {return}

      this.getAssets();

      if(!this.$route.query.tab){
        this.actionTabRouter(this.actionsTabs.findIndex(tab => tab.label.toLowerCase() === this.$store.getters.getCampaignTab));
      }
      else{
        this.actionTabRouter(this.actionsTabs.findIndex(tab => tab.label.toLowerCase() === this.$route.query.tab));
      }  

      this.$root.$on('reloadStatData', (updatedData, burstId, modeId) =>{ 
        // loop through the bursts and update the data
        this.dashboardStructureByCampaignData.bursts.forEach((burst,boi) => {
            // update the stats array
            // replace the data in the dashboardTargets
            burst.mediaOwners.forEach((item, moi) => {
                item.campaignBurstScreens.forEach((cbs, cbi) => {
                    if (cbs.campaignBurstScreen.id == updatedData.campaignBurstScreen.id) {
                      let elemToUpdate = this.dashboardStructureByCampaignData.bursts[boi].mediaOwners[moi].campaignBurstScreens[cbi];
                      // only update the orginal data's .dashboardStats for the mode (mId) that was updated
                      elemToUpdate.dashboardStats.forEach((ds, dsi) => {
                        // get the relevant data in the updatedData
                        let updatedDataStat = updatedData.dashboardStats.find(uds => uds.mId == modeId && ds.mId == modeId && uds.kId == ds.kId && uds.dId == ds.dId && uds.dt == ds.dt)
                        if(updatedDataStat)
                          elemToUpdate.dashboardStats[dsi] = updatedDataStat
                      })

                      elemToUpdate.dashboardStatsGrouped.forEach((dsg, dsgi) => {
                        // get the relevant data in the updatedData
                        let updatedDataStat = updatedData.dashboardStatsGrouped.find(uds => uds.mId == modeId && dsg.mId == modeId && uds.kId == dsg.kId && uds.dId == dsg.dId && uds.dt == dsg.dt)
                        //console.log("modeId", modeId, "kId", dsg.kId, "dId", dsg.dId, "dt", dsg.dt)
                        if(updatedDataStat)
                          elemToUpdate.dashboardStatsGrouped[dsgi] = updatedDataStat
                      })
                    }
                })
            })
        })
        this.refreshStatData(burstId)
      })

      // Lazy Load Bursts
      this.$root.$on('loadNextBurst', (target) => {
        setTimeout(() => {
          const index = this.lazyLoadBursts[target].length
          const nextBurst = this.campaign.campaignBursts.slice(index, this.lazyLoadBursts[target].length + 1);
          if(nextBurst.length > 0)
          {
            if(this.arbSync[index] == null)
              this.arbSync.push(0)
            this.lazyLoadBursts[target].push(...nextBurst)
          }
          else
          {
            this.lazyBurstsLoaded = true
          }
        }, 100)
      })
    },
    // destroy the event listeners
    beforeDestroy() {
      this.$root.$off('reloadStatData')
      this.$root.$off('loadNextBurst')
      this.$root.$off('updateOrDeleteCampaign')
      this.$root.$off('updateBurst')
      // gartbage collection
      this.dashboardStructureByCampaignData = null
      this.dashboardStructureByCampaign = null
      this.dashboardStatMetricTypes = null
    },
    async created () {
      await this.onCreated();

      /*

      // Global Functions to update the campaign after modifing a milestone
      this.$root.$on('updateOrDeleteCampaign', (action, item) => {{
            if (this.burstCopy.id === item.id) {
                if (action === 'update') {
                    this.burstCopy.workflowStatus = item;
                } else if (action === 'delete') {
                    this.burstCopy.workflowStatus = { id: 0 }
                }
                this.$nextTick()
            }
        }})
      */
      // update or delete burst from the campaign.campaignBursts using set
      this.$root.$on('updateBurst', (item) => {
        this.campaign.campaignBursts = this.campaign.campaignBursts.map((burst) => {
          if (burst.id === item.id) {
            return item
          }
          return burst
        })
        this.$nextTick()
      })
        

      this.$root.$on('updateOrDeleteCampaign', (action, item) => {
                this.campaign.campaignBursts.forEach((campaignBurst) => {
                    if (campaignBurst.id === item.id) {
                        if (action === 'update') {
                            campaignBurst.workflowStatus = item;
                        } else if (action === 'delete') {
                            campaignBurst.workflowStatus = { id: 0 }
                        }
                    }
                })
              this.$nextTick()
          })
      {  
        if(!this.lazyBurstsLoaded || this.arbSync.length == 0)
        { 
          const beginInt = !this.lazyBurstsLoaded ? 0 : -1;
          this.arbSync.push(-1)
          this.lazyLoadBursts["targets"] = this.campaign.campaignBursts.slice(0, 1)
          this.lazyLoadBursts["reports"] = this.campaign.campaignBursts.slice(0, 1)
        }
      }
      this.deferLoadingCall();

      // Get dropdown values
      this.demographics = await RouteController.getDemographics()
      this.granularityAudiences = await RouteController.getGranularityAudiences()
      let rvers = await RouteController.getRouteVersions()
      this.routeVersions.push(...rvers.data)
    },
    methods: {
      async getAssets() {
        console.log("getAssets");
        if(!this.campaignId) return;
        const { data = null } = await CampaignController.getAssets(this.campaignId)
        .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

        if(data == null) return this.campaignAssets = [];

        this.campaignAssets = [...data];
      },
      handleRouteRequestsUpdated(updatedRouteRequests) {
        this.routeRequests = updatedRouteRequests
      },

      handleRouteRequestAdded(newRouteRequest) {
        this.routeRequests.push(newRouteRequest)
        this.routeRequest = newRouteRequest
        // You might want to trigger a fetch of new data here
      },

      async handleRouteRequestChanged(newRouteRequest) {
        this.routeRequest = newRouteRequest
        // await this.fetchDashboardStatData() // Handled by the watcher as the Tracking tab doesn't have a route request selector
      },

      // function to add the campaignRouteRequest
      addCampaignRouteRequest() {
        let campaignId = this.campaignId

        let demographicCustom = this.routeRequestForm.demographicCustom == null ? '' : this.routeRequestForm.demographicCustom

        if(this.routeRequestForm.routeVersionId == null || this.routeRequestForm.routeDemographicId == null || this.routeRequestForm.routeGranularityAudienceId == null)
        {
          this.$root.$emit('snackbarError', 'Please select a Route Version, Demographic and Granularity Audience')
          return
        }
        
        let routeRequestObj = {
          campaignId: campaignId,
          routeVersionId: this.routeRequestForm.routeVersionId,
          routeDemographicId: this.routeRequestForm.routeDemographicId,
          routeGranularityAudienceId: this.routeRequestForm.routeGranularityAudienceId,
          demographicCustom: demographicCustom,
        }

        RouteController.addCampaignRouteRequest(routeRequestObj)
          .then((res) => {
            this.routeRequests.push(res.data)
            this.$root.$emit('snackbarSuccess', 'Route Request Added')
            
            // Reset the form
            this.routeRequestForm = {
              routeVersionId: null,
              routeDemographicId: null,
              routeGranularityAudienceId: null,
              demographicCustom: '',
            }
          })
          .catch((error) => {
            this.$root.$emit('snackbarError', 'Error adding Route Request: ' + error.response.data.message)
          })
      },

      // sum of values in an array
      sumValues(arr) {
        return arr.reduce((a, b) => a + b, 0)
      },

      getKpiIdFromName(kpiName) {
          let o = this.kpiListObjects.KPIs.find(kpi => kpi.name === kpiName)
          if(o) return o.id
      },

      getKpiIdFromShortName(shortName) {
          let o = this.kpiListObjects.KPIs.find(kpi => kpi.shortName === shortName)
          if(o) return o.id
      },

      getKpiShortNameFromName(kpiName) {
          let o = this.kpiListObjects.KPIs.find(kpi => kpi.name === kpiName)
          if(o) return o.shortName
      },

      getDesignationIdFromShortName(shortName) {
          let o = this.kpiListObjects.DESIGNATIONs.find(designation => designation.shortName === shortName)
          if(o) return o.id
      },

      getModeIdFromShortName(shortName) {
          let o = this.kpiListObjects.MODEs.find(mode => mode.shortName === shortName)
          if(o) return o.id
      },

      getKpiShortNameFromId(id) {
          let o = this.kpiListObjects.KPIs.find(kpi => kpi.id === id)
          if(o) return o.shortName
      },

      getKpiNameFromId(id) {
          let o = this.kpiListObjects.KPIs.find(kpi => kpi.id === id)
          if(o) return o.name
      },

      addCommas(number) {
          const num = parseFloat(number).toFixed(2);
          // Convert the number to a string
          const numberString = num.toString();
          // Split the number into integer and decimal parts
          const [integerPart, decimalPart] = numberString.split('.');
          // Add commas to the integer part
          const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

          // Combine the integer part with the decimal part (if any)
          const result = decimalPart !== undefined ? numberWithCommas + '.' + decimalPart : numberWithCommas;
          // Return the result
          
          return result;
      },
      removeCommas(number)
        {
              return number.replace(/,/g, '')
      },

      // function to get the campaign data grouped (totals)
      getCampaignStatsTotals(mode)
      {
          let modeId = this.getModeIdFromShortName(mode)
          let actualId = this.getDesignationIdFromShortName('actual')
          let targetId = this.getDesignationIdFromShortName('target')
          // first flat() the data
          if(!this.dashboardStructureByCampaign.bursts) return []
          let data = this.dashboardStructureByCampaign.bursts.flat().map(b => b.mediaOwners).flat().map(mo => mo.campaignBurstScreens).flat().map(cbs => cbs.dashboardStatsGrouped).flat()
          // loop through the data and sum the values
          // [{ name: 'Impacts', target: 0, actual: 0, performance: % }, { name: 'Plays', target: 0, actual: 0, perfomance: % }, ... }]
          let outObj = []
          data.forEach(stat => {
            if(stat.mId == modeId)
            {
              // find existing in outObj
              let obj = outObj.find(o => { 
                  let kpiId = this.getKpiIdFromName(o.name)
                  return kpiId === stat.kId
                }
              )

              if(obj) {
                if(stat.dId == targetId)
                  obj.target += stat.v
                else if(stat.dId == actualId)
                  obj.actual += stat.v
              } else {
                let kpiName = this.getKpiNameFromId(stat.kId)
                let kpiShortName = this.getKpiShortNameFromId(stat.kId)
                if(stat.dId == targetId)
                  outObj.push({ name: kpiName, shortName: kpiShortName, target: stat.v, actual: 0 })
                else if(stat.dId == actualId)
                  outObj.push({ name: kpiName, shortName: kpiShortName, target: 0, actual: stat.v })
              }
            }
          });
      
          // recaluclate frequency (impacts/reach)
          // find the average_frequency
          let avgFrequency = outObj.find(o => o.shortName === 'average_frequency')
          let impacts = outObj.find(o => o.shortName === 'impacts')
          let reach = outObj.find(o => o.shortName === 'reach')
          if(avgFrequency && impacts && reach) {
            avgFrequency.actual = reach.actual == 0 ? 0 : (impacts.actual / reach.actual).toFixed(2)
            avgFrequency.target = reach.target == 0 ? 0 : (impacts.target / reach.target).toFixed(2)
          }

          // calculate the performance, round to 2 decimal places
          outObj.forEach(o => {
            o.performance = (o.target === 0 ? 0 : Math.round((o.actual / o.target) * 10000) / 100) + "%"
            o.target = this.addCommas(Math.round(o.target))
            o.actual = this.addCommas(Math.round(o.actual))
          })

          // delete all shortnames
          outObj = outObj.map(o => { delete o.shortName; return o })

          // take first 3 items
          //outObj = outObj.slice(0, 3)

          return outObj
      },

      async fetchDashboardStatData() {
          this.isLoading = true
          await ReportingController.getDashboardStatMetricTypes()
            .then((res) => {
              this.dashboardStatMetricTypes = res.data
              // KPIs become global
              let KPIs = this.dashboardStatMetricTypes.dashboardKPIs;
              let MODEs = this.dashboardStatMetricTypes.dashboardModes;
              let DESIGNATIONs = this.dashboardStatMetricTypes.dashboardDesignations;

              // should they be able to view ICE Impacts?
              // remove ice_impacts from the list
              if(this.$store.state.Permissions.showICEImpacts === false) {
                KPIs = KPIs.filter(kpi => kpi.shortName !== 'ice_impacts')
              }

              // make the KPI lists
              this.kpiList = KPIs.map(kpi => kpi.name)
              this.kpiListObjects = {
                KPIs: KPIs,
                MODEs: MODEs,
                DESIGNATIONs: DESIGNATIONs
              }
              if(this.chartMetric == '') // set initial
                this.chartMetric = this.kpiList[0]
            })
            .catch((err) => {
              console.log(err)
            })

          let routeRequestId = this.routeRequest?.id || 0
          await RouteController.getDashboardTargetsByCampaign(this.campaignId, routeRequestId)
            .then((res) => {
              this.dashboardStructureByCampaignData = res.data
              this.isLoading = false
              this.deferLoadingCall()
            })
            .finally(() => {
              this.isLoading = false
            })
            .catch((err) => {

            })
      },

      refreshStatData(burstId) {
        if(!this.dashboardStructureByCampaignData.bursts)
          return
        
        this.dashboardStructureByCampaign = this.dashboardStructureByCampaignData
        this.totals = this.getCampaignStatsTotals('auto')
        
        // get RCF from RouteController await
        
        let routeRequestId = this.routeRequest?.id || 0
        /*
        RouteController.getCampaignRCF(this.campaignId, routeRequestId)
          .then((res) => {
            console.log("RCF", res.data)
            // append rcf to this.totals
            let rcf = res.data
            // append two arrays
            // remove reach cover frequency
            this.totals = this.totals.filter(t => t.name !== 'Reach' && t.name !== 'Cover' && t.name !== 'Average Frequency')
            console.log("this.totals", this.totals)
            this.totals = this.totals.concat(rcf)
            
          })
          .catch((err) => {
            console.log(err)
          })
        */
        // find the index of the burstId in the lazyLoadBursts
        if(burstId) {
          let index = this.lazyLoadBursts["reports"].findIndex(b => b.id === burstId)
          if(index > -1) {
            this.arbSync[index]++
          }
        }
        // delete the dashboardStats in the flattened campaignBurstScreens()
        //this.dashboardStructureByCampaign.bursts.flat().map(b => b.mediaOwners).flat().map(mo => mo.campaignBurstScreens).flat().map(cbs => delete cbs.dashboardStats)
      },

      async onCreated(){
        this.campaignId = this.$route.query.cid

          this.overlay = true
        if(this.$store.state.Permissions.analyticsEdit === false){
          this.isPanelExpanded = 0
        } else {
          this.isPanelExpanded = 1
        }
        // Check if is media planner, then addFormats === false, otherwise addFormats === true
        if(this.$store.state.Permissions.screenAdd){
          this.addFormats = true
        }
        // 
        if(this.$store.state.Permissions.screenView){
          this.showFormats = true
        }

        // Get Delivery Types
        const res = await CampaignController.deliveryTypes()
        this.deliveryTypes = res.data
        for (let i = 0; i < triggersList.triggers.length; i++)
          this.availableTriggers.push(triggersList.triggers[i])
        this.initVueLocal(['actionTab'])
        await this.getCampaignById()
        await this.getAllChartData()
        this.reportedCampaignBursts = this.campaign.campaignBurstscre
        // Get all formats
        this.getFormats()
        // Force update data (just in case)
        this.$nextTick()
        this.overlay = false

        // Create global function updateSelectedFilterBurst that is acceses with emit from child
        this.updateSelectedFilterBurst = (burstId) => {
          this.selectedBurst = burstId
        }
      },
      actionTabRouter(i){
        this.getAssets();
        //this.refreshStatData()
        this.tabParamName = this.actionsTabs[i].label.toLowerCase();
        this.setVueLocal('actionTab', i);
        this.actionTab = i;
        this.$store.dispatch('storeCampaignTab', this.tabParamName);
        this.lazyLoadBursts = {
          targets: [],
          reports: []
        }
        this.arbSync = []
        if (this.tabParamName !== null) {
            this.$router.push({
            name: 'Campaign',
            query: { cid: this.$route.query.cid, tab: this.tabParamName },
          }).then(() => {
            if(!this.lazyBurstsLoaded || this.arbSync.length == 0)
            {
              const beginInt = !this.lazyBurstsLoaded ? -1 : 0;
              this.arbSync.push(beginInt)
              setTimeout(() => {
                this.lazyLoadBursts["targets"] = this.campaign.campaignBursts.slice(0, 1)
                this.lazyLoadBursts["reports"] = this.campaign.campaignBursts.slice(0, 1)
              }, 100)
            }
            this.deferLoadingCall()
          }).catch(()=>{});
        }
      },
      deferLoadingCall() {
        if(this.deferLoading)
        {
          if(!this.isLoading && this.lazyLoadBursts["reports"].length > 0)
          {
            this.deferLoading = false
            // loop through the bursts and update the data
            this.lazyLoadBursts["reports"].forEach(x => {
              
              this.refreshStatData(x.id)
            })
          }
          else
          {
            console.log('Route Requests loaded before Burst Info')
          }
        }
      },
      scrollToGraph() {
        const graphElement = this.$refs.graph.$el; // Get the DOM element of the graph
        const yOffset = graphElement?.getBoundingClientRect().top;
        const targetScrollPosition = window.pageYOffset + yOffset;

        // Smoothly scroll to the graph
        window.scrollTo({
          top: targetScrollPosition,
          behavior: "smooth",
        });
      },
      togglePanel () {
        this.isPanelExpanded = !this.isPanelExpanded;
      },
      truncate(str, maxlength) {
        return (str.length > maxlength) ?
          str.slice(0, maxlength - 1) + '…' : str;
      },
      // Performance Summary
      async downloadDashboardReport(campaignId,routeRequestId) {
        this.generatingDashboardReport = true;
        let res = await ReportingController.getDashboardReportDownload(campaignId,routeRequestId).catch(async error => {
          this.generatingDashboardReport = false;
          const errorData = JSON.parse(await error.response.data.text()); // Get Error Message from JSON, ResponseType: Blob
          this.$root.$emit('snackbarError', '' + errorData?.message || 'An error occurred');
        })
        const folderPath = decodeURI(res.headers['content-disposition'].split("filename=")[1].split(';')[0]).replaceAll('"','').split('\\')
        const fileName = folderPath[folderPath.length-1]
        const blob = new Blob([res.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
        this.generatingDashboardReport = false;
      },

      // PCA v1
      async downloadPCAReport(campaignId) {
        this.generatingPCA = true; 
        let res = await ReportingController.getPCAReportDownload(campaignId,this.routeRequest.id).catch(async err => {
          this.generatingPCA = false;
          const errorData = JSON.parse(await error.response.data.text()); // Get Error Message from JSON, ResponseType: Blob
          this.$root.$emit('snackbarError', '' + errorData?.message || 'An error occurred');
        })
        const folderPath = decodeURI(res.headers['content-disposition'].split("filename=")[1].split(';')[0]).replaceAll('"','').split('\\')
        const fileName = folderPath[folderPath.length-1]
        const blob = new Blob([res.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
        this.generatingPCA = false;
      },
      // Post Campaign Analysis PCA v2
      async downloadPostCampaignAnalysisPCAReport(campaignId,routeRequestId) {
        this.generatingPostCampaignAnalysisPCAReport = true;
        let res = await ReportingController.GetPostCampaignAnalysisPCAReportDownload(campaignId,routeRequestId).catch(async error => {
          this.generatingPostCampaignAnalysisPCAReport = false;
          const errorData = JSON.parse(await error.response.data.text()); // Get Error Message from JSON, ResponseType: Blob
          this.$root.$emit('snackbarError', '' + errorData?.message || 'An error occurred');
        })
        const folderPath = decodeURI(res.headers['content-disposition'].split("filename=")[1].split(';')[0]).replaceAll('"','').split('\\')
        const fileName = folderPath[folderPath.length-1]
        const blob = new Blob([res.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
        this.generatingPostCampaignAnalysisPCAReport = false;
      },
      // Function to open the edit campaign modal
      openCampaignEditModal(selectedCampaign, action) {
        this.showCampaignEditFormDialog = true
      },
      openIssue(index, issue, burst){
            this.selectedIssue = issue
            this.softSelectedBurst = burst
            this.issueIndex = index
            this.openIssueModal = true
        },
        closeIssue(){
            this.openIssueModal = false
        },
        /*
      openIssue(issue, burst, campaign){
        this.selectedIssue = issue
        if(!this.selectedBurst){
          this.selectedBurst = burst
          this.selectedCampaign = campaign
        }
        this.openIssueModal = true
      },
      closeIssue(){
        this.openIssueModal = false
        this.createIssueModal = false
      },
      */
      async addScreenToBurst() {
        try {
          this.disableAddScreenBtn = true;     
            for (const selectedBurst of this.selectedBurstToAddScreen) {
              const burstObj = {
                campaignBurstId: selectedBurst.id,
                screenGroupId: this.selectedScreen.id,
                startDate: this.selectedDate.date && this.selectedDate.date.length > 0
                  ? this.selectedDate.date[0] + 'T00:00:00.000Z'
                  : null,
                endDate: this.selectedDate.date && this.selectedDate.date.length > 0
                  ? this.selectedDate.date[1] + 'T00:00:00.000Z'
                  : null,
                triggerList: this.selectedTriggers,
              };
              try {
                const res = await CampaignController.addBurstScreen(burstObj);
                // Handle successful API response
                const newBurst = res.data.campaignBurst;
                const oldBurst = this.campaign.campaignBursts.find(item => item.id === newBurst.id);
                const burstAtIndex = this.campaign.campaignBursts.indexOf(oldBurst);
                this.campaign.campaignBursts.splice(burstAtIndex, 1, newBurst);
                this.$nextTick();
              } catch (err) {
                  // Handle API call error
                  console.error('API call error:', err);
                  this.$root.$emit('snackbarError', '' + err.response?.data?.message || 'An error occurred');
                  // Handle specific error scenarios if needed
                }
              }
              // TODO: Reset UI and close dialogs after the loop finishes
              this.selectedDate.date = null;
              this.selectedTriggers = [];
              this.addFormatDialog = false;
              this.availableFormatsDialog = false;
              this.addScreenGlobally = false;
          } catch (error) {
              // TODO: Handle any uncaught errors here
              console.error('Uncaught error:', error);
              this.$root.$emit('snackbarError', 'An unexpected error occurred');
          } finally {
              // Clean up UI and enable button regardless of success or failure
              this.disableAddScreenBtn = false;
              this.e1 = 1
              this.selectedBurstToAddScreen = null
          }
      },
      quickSortFormats(arr) {
        let clone = [...arr]
        clone.sort((a, b) => {
            let fnna = a.mo ? a.mo.fn : a.mediaOwner.friendlyName;
            let fnnb = b.mo ? b.mo.fn : b.mediaOwner.friendlyName;
            let nna = a.mo ? a.mo.name : a.mediaOwner.name;
            let nnb = b.mo ? b.mo.name : b.mediaOwner.name;
            return fnna.localeCompare(fnnb) || nna.localeCompare(nnb);
          }
        )
        return clone
      },
      updatedSearchFormats() {
        if (this.searchFieldScreens === null || this.searchFieldScreens === undefined || this.searchFieldScreens === '') {
          this.getFormats()
          return
        }
        if (this.searchFieldScreens.length < 2) {
          return
        }
        if (this.searchTimeout) {
          clearTimeout(this.searchTimeout)
        }
        this.searchTimeout = setTimeout(() => {
          this.searchFormats()
        }, 1000)
      },
      searchFormats() {
        ScreenController.searchFormats(this.searchFieldScreens)
          .then((res) => {
            this.formats = res.data
          })
      },

      clearSearch() {
        this.searchFieldScreens = null
        // Get all available screens for data table
        this.getFormats()
      },
      getFormats() {
        ScreenController.allScreens().then((res) => {
          this.formats = res.data
        })
      },
      // Function to update the bursts
      updateBursts(newBurst) {
        this.campaign.campaignBursts.push(newBurst)
        // this.$nextTick()
      },
      // Filter color based on issue status
      filterColor(status) {
        switch(status) {
          case 1:
            return '#499F68'
          case 2:
            return 'grey'
          case 3:
            return '#F464A9'
          case 4:
            return '#4AA6D5'
          case 0:
            return 'transparent'
          default:
            return 'transparent'
        }  
      },
      // Progress v-progress-circular
      getProgress(campaign) {
                if(!campaign)
                    return 0
                /* Get the total number of bursts without the bursts where workflowStatus.id === 0 (not started) or workflowStatus.categories is empty
                let totalBursts = 0
                campaign.campaignBursts.forEach((burst) => {
                    if (burst.workflowStatus.id !== 0 && burst.workflowStatus.categories.length > 0) {
                        totalBursts++
                    }
                })
                let completedBursts = 0
                campaign.campaignBursts.forEach((burst) => {
                    if (burst.workflowStatus.status === 1) {
                        completedBursts++
                    }
                })
                */
                let totalTasks = 0
                let completedTasks = 0
                // Get the total number of tasks and the number of completed tasks
                campaign.campaignBursts.forEach(burst => {
                    if(burst){
                        if(burst.workflowStatus){
                            if(burst.workflowStatus.categories.length > 0){
                                burst.workflowStatus.categories.forEach(category => {
                                    category.dIs.forEach(dIssue => {
                                        dIssue.tasks.forEach(task => {
                                            totalTasks++
                                            if(task.s === 1)
                                                completedTasks++
                                        })
                                    })
                                })
                            }
                        }
                    }
                })
                // Return the percentage of completed tasks and round it to the nearest integer, if there's no tasks, return 0
                if(totalTasks === 0)
                    return 0
                else
                    return Math.round((completedTasks / totalTasks) * 100)
            },
      handleProgressClick(burstId, campaign) {
        this.selectedBurst = burstId
        this.selectedCampaign = campaign
      },
      async getLatestPingBacks() {
//        api/Campaign/GetLatestPingBacks?campaignId=4
        var data = await CampaignController.getLatestPingBacks(this.campaignId);
        console.log("ping backs",data.data);
        this.latestPingBacks = [...data.data];
      },
      addCampaignStatus() {
          // Live Campaigns
          if (moment().isBetween(this.campaign.startDate, moment(this.campaign.endDate).add(1, 'days'))) {
            this.campaign.status = { type: 'live', color: 'primary' }
          }
          // Pending Campaigns
          else if (moment(new Date()).isBefore(this.campaign.startDate) || this.campaign.startDate === null || this.campaign.endDate === null) {
            this.campaign.status = { type: 'pending', color: 'tertiary' }
          }
          // Finished Campaigns
          else if (moment(this.campaign.endDate).isBefore(new Date())) {
            this.campaign.status = { type: 'completed', color: 'secondary' }
          } else {
            this.campaign.status = { type: 'pending', color: 'tertiary' }
          }
      },
      // Function to open the edit campaign modal
      clickAction(selectedCampaign, action) {
        this.$emit('clickAction', selectedCampaign, action)
      },
      async getCampaignById() {
        await CampaignController.getCampaign(this.$route.query.cid, true)
        .then((res) => {
          this.campaign = res.data
          for (let x = 0; x < this.campaign.campaignBursts.length; x++) {
            for (let j = 0; j < this.campaign.campaignBursts[x].campaignBurstScreens.length; j++) {
              this.mediaOwnersFromBurst.push({
                name: this.campaign.campaignBursts[x].campaignBurstScreens[j].screen.mo ? this.campaign.campaignBursts[x].campaignBurstScreens[j].screen.mo.fn : this.campaign.campaignBursts[x].campaignBurstScreens[j].screen.mediaOwner.friendlyName,
                id: this.campaign.campaignBursts[x].campaignBurstScreens[j].screen.mo ? this.campaign.campaignBursts[x].campaignBurstScreens[j].screen.mo.id : this.campaign.campaignBursts[x].campaignBurstScreens[j].screen.mediaOwner.id,
              })
            }
          }
/*          
          // if there are more than X bursts, isPanelExpanded = 1 and selectedBurst = the last burst
          if (this.campaign.campaignBursts.length > 1 && this.actionTab <= 4) {
            this.isPanelExpanded = 0
            this.selectedBurst = this.campaign.campaignBursts[this.campaign.campaignBursts.length - 1].id
            //this.selectedBurst = this.campaign.campaignBursts[0].id
          }
*/
          this.mediaOwnersFromBurst = uniqBy(this.mediaOwnersFromBurst, 'id');
          this.addCampaignStatus()
          this.nameEditField = res.data.name
          this.descriptionEditField = res.data.description
          this.breadcrumbItems[1].text = res.data.name
          this.$store.dispatch('storeCampaign', this.campaign);
          this.$nextTick()
        })
      },

      async getAllChartData() {
        // Update the time the chart data was updated
        this.chartUpdatedTime = new Date()
        await this.getLiveCampaignAnalytics()
      },

      // Get ping back data (heartbeat)
      async getLiveCampaignAnalytics() {
        const { data = null } = await ReportingController.getLiveCampaignAnalytics(this.$route.query.cid).catch(err => {})
      
        if(data && data !== '') {
          this.dashboardAnalytics = []
          this.dashboardAnalytics.push(data)
          
          const chartValues = await ReportingController.getChartValues({
            campaignId: parseInt(this.$route.query.cid),
            type: 'bar',
            indicator: 'live-plays-hour',
            granularity: 'day',
            playerId: 0,
          }).catch((err) => { })

          if(chartValues) {
            this.renderBarChart = true
            this.barChartOptions.xaxis.categories = chartValues.data.categories
            this.barChartOptions.series = chartValues.data.series
            this.barChartData.series = chartValues.data.series
          }
        
        }
      },

      refreshChartData() {
        if (this.barChartTimeOption === 'day') {
          ReportingController.getChartValues({
            campaignId: parseInt(this.$route.query.cid),
            type: 'bar',
            indicator: 'live-plays-hour',
            granularity: 'hour',
            playerId: 0,
          })
          .then((res) => {
            this.renderBarChart = true
            this.barChartOptions.xaxis.categories = []
            this.barChartOptions.xaxis.categories = res.data.categories
            this.barChartOptions.series = res.data.series
          })
        }
        else {
          ReportingController.getChartValues({
            campaignId: parseInt(this.$route.query.cid),
            type: 'bar',
            indicator: 'live-plays-hour',
            granularity: 'day',
            playerId: 0,
          }).then((res) => {
            this.renderBarChart = true
            this.barChartOptions.xaxis.categories = []
            this.barChartOptions.xaxis.categories = res.data.categories
            this.barChartOptions.series = res.data.series
          })
        }
        ReportingController.getLiveCampaignAnalytics(this.$route.query.cid)
        .then((res) => {
          this.mergedDashboardAnalytics[0].playsAllTime = res.data.playsAllTime
          this.mergedDashboardAnalytics[0].playsToday = res.data.playsDay
          this.mergedDashboardAnalytics[0].playsWeek = res.data.playsWeek
        })
        this.chartUpdatedTime = new Date()
      },

      async updateBurstCalled() {
        await this.getAllChartData()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .explorationContainer .exploreCanvas .canvasFlexBox .displayAreaContainer {
    width: 100%;
    background: #121212 !important;
    position: relative;
  }

  .apexcharts-tooltip {
    background: #f3f3f3;
    color: #1c1c1c;
  }

  .loading-bars .v-skeleton-loader__image.v-skeleton-loader__bone { height: 350px; }
  .loading-axis .v-skeleton-loader__image.v-skeleton-loader__bone { height: 30px; }

  .apexcharts-menu-item {
    color: black !important;
  }
  .v-expansion-panel-header {
    padding: 0px 24px 0px 0px;
  }
  .subIssues {
    min-width: 100%;
}
.subIssue {
    //: pointer;
    /* add transition speed 300 */
    transition: 0.3s;
    height: 25px;
}
.subIssue:first-child,
.roundCorners  {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.oneIssue {
    border-radius: 10px;
}
.subIssue:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.subIssue:not(:last-child) {
    border-right: 0.1px solid black;
}
.subIssue:hover {
    /* add transition speed 300 */
    transition: 0.3s;
    background-color: black;
}
</style>
<style scoped>
.customFontSize{
  font-size: 10px !important;
}
/* Media quqery to change the font size of the subIssue if the screen is smaller than 600px */
@media only screen and (max-width: 600px) {
  .customFontSize{
    font-size: 8px !important;
  }
}
</style>